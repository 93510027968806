<template>
  <el-dropdown trigger="click">
    <el-button size="mini" icon="el-icon-setting">
      <i class="el-icon-arrow-down el-icon--right"></i>
    </el-button>
    <el-dropdown-menu slot="dropdown" class="costum-menu">
      <el-dropdown-item v-for="(column, index) in arr" :key="index">
        <el-checkbox :checked="column.show" @change="check(column.column, $event)">{{ column.title }}</el-checkbox>
      </el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</template>
<style>
.el-dropdown-menu__item,
.el-checkbox__label,
.el-checkbox {
    font-size: 14px !important;
}
</style>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  props: {
    columns: { type: Object },
    modelName: String,
    method: String,
  },
  data() {
    return {
      show: false,
      arr: [],
      way: this.modelName + "/" + (this.method ? this.method : "updateColumn"),
      qwerty: [],
    };
  },
  computed: {
    ...mapGetters({
      columnShow: "appColumns/columns",
    }),

    half() {
      return (arr, start = 0) => {
        const i = arr.length % 2 === 0 ? arr.length / 2 : (arr.length + 1) / 2
        return arr.slice(start, i)
      }
    }
  },
  mounted() {
    for (var key in this.columns) {
      var keyVal = [];
      keyVal[0] = key;
      keyVal[1] = this.columns[key].show;
      this.qwerty.push(keyVal);
    }
    this.appColumn({ model: this.modelName, columns: this.qwerty });
  },
  watch: {
    columnShow: function (val) {
      this.show = false;
      for (const key in val) {
        if (val.hasOwnProperty(key)) {
          this.show = val[key];
          if (val[key] == 'yes') {
              this.show = true;
          } else if (val[key] == 'no') {
              this.show = false;
          }
          const payload = { key: key, value: this.show };
          this.$store.dispatch(this.way, payload)
        }
      }
      this.filtered_columns();
    },
  },
  methods: {
    ...mapActions({
      appColumn: "appColumns/index",
      UpdateappColumn: "appColumns/store",
    }),
    check: function (column, event) {
      this.$emit('c-change', { key: column, value: event });
      this.UpdateappColumn({ model: this.modelName, column: column, value: event })
    },
    filtered_columns() {
      this.arr = [];
      for (const key in this.columns) {
        if (this.columns.hasOwnProperty(key)) {
          const element = this.columns[key];
          if (element.hasOwnProperty('changeable')) {
            if (element.changeable) {
              this.arr.push(element);
            }
          } else {
            this.arr.push(element);
          }
        }
      }
    }
  }
}
</script>
<style >
.el-dropdown-menu__item>label {
    margin-bottom: 0.1rem !important;
}

.el-dropdown-menu {
    max-height: 720px !important;
    overflow-y: auto;
}

.costum-menu {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 12px;
    left: calc(100% - 450px) !important;
    width: 450px;
}

@media (max-width: 1200px) {
    .costum-menu {
        left: 20% !important;
        min-width: 250px ;
        width: unset ;
        display: flex ;
        flex-direction: column;
    }
}
</style>
