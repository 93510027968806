import { i18n } from '@/utils/i18n';
export const columnsBank = {
    bank: {
        title: i18n.t('message.bank')
    },
    address: {
        title: i18n.t('message.address')
    },
    correspondent_account: {
        title: i18n.t('message.correspondent_account')
    },
    checking_account: {
        title: i18n.t('message.accaunt')
    }
};