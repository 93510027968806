<template>
    <el-select
        :value="selected"
        @input="dispatch"
        :placeholder="plc || $t(`message.status_0`)"
        filterable
        clearable
        :size="size"
        class="d-block"
    >
        <el-option
            v-for="status in statuses"
            :key="status.id"
            :label="status.title"
            :value="status.id"
        ></el-option>
    </el-select>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import inventory from "@/utils/mixins/components/inventory";

export default {
    mixins: [inventory],
    props: ["status_id"],
    watch: {
        status_id: {
            handler: function () {
                this.dispatch(this.status_id);
            },
            deep: true,
            immediate: true,
        },
    },
    mounted() {
        if (this.statuses && this.statuses.length === 0) {
            this.getInventory();
        }
    },
    computed: {
        ...mapGetters({
            statuses: "statuses/inventory",
        }),
    },
    methods: {
        ...mapActions({
            getInventory: "statuses/inventory",
        }),
    },
};
</script>