import { sort } from "../properties/sort"
import { filter } from "./properties/filter"
import { pagination } from "../properties/pagination"
import { model } from "./properties/model"
import { i18n } from "@/utils/i18n"

export const mutations = {
  SET_LIST: (state, debts) => (state.list = debts),
  INCREMENT_LAST_ID: (state) => ++state.lastId,
  SET_INVENTORY: (state, debts) => (state.inventory = debts),
  SET_SEARCH_LIST: (state, debts) => (state.search_list = debts),
  SET_FILTER: (state, filter) => (state.filter = filter),
  SET_PAGINATION: (state, pagination) => (state.pagination = pagination),
  SET_SORT: (state, sort) => (state.sort = sort),
  SET_MODEL: (state, model) => {
    state.model = model
  },
  // SET_LAST_MODEL_ID: (state, id) => (state.last_model_id = id),
  UPDATE_COLUMN: (state, obj) => {
    state.columns[obj.key].show = obj.value
  },
  UPDATE_SORT: (state, sort) => {
    state.sort[sort.column] = sort.order
  },
  UPDATE_PAGINATION: (state, pagination) => {
    state.pagination[pagination.key] = pagination.value
  },
  EMPTY_MODEL: (state) => {
    state.model = JSON.parse(JSON.stringify(model))
  },
  REFRESH: (state) => {
    state.filter = JSON.parse(JSON.stringify(filter))
    state.sort = JSON.parse(JSON.stringify(sort))
    state.pagination = JSON.parse(JSON.stringify(pagination))
  },
  EMPTY_SEARCH: (state) => {
    state.search_list = []
  },
  UPDATE_LANG_COLUMN: (state) => {
    state.columns.id.title = "№";
    state.columns.debt_type.title = i18n.t('message.debt_type');
    state.columns.total_price.title = i18n.t('message.total_amount');
    state.columns.paid_price.title = i18n.t('message.paid');
    state.columns.partner_clinic.title = i18n.t('message.partner_clinic');
    state.columns.partner_doctor.title = i18n.t('message.partner_doctor');
    state.columns.created_at.title = i18n.t('message.date');
    state.columns.settings.title = i18n.t('message.settings');
  },
}
