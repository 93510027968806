export const getters = {
  list: state => state.list,
  model: state => state.model,
  columns: state => state.columns,
  columnsBank: state => state.columnsBank,
  columnsContact: state => state.columnsContact,
  filter: state => state.filter,
  pagination: state => state.pagination,
  sort: state => state.sort,
  rules: state => state.rules,
  inventory: state => state.inventory
};