<template>
  <el-select
    :value="selected"
    @input="dispatch"
    :placeholder="plc || $t(`message.floor`)"
    filterable
    clearable

  >
    <el-option v-for="item in filtered" :key="item.id" :label="item.name" :value="item.id"></el-option>
  </el-select>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import inventory from '@/utils/mixins/components/inventory';

export default {
  mixins: [inventory],
  props: ['corps_id', 'floor_id'],
  mounted() {
    if (this.floors && this.floors.length === 0) {
      this.getInventory();
    }
  },
  watch: {
    floor_id:{
       handler: function (val, oldVal) { 
          this.selected = val;
          this.$emit("input", this.selected);
       },
       deep: true,
       immediate: true
    },
    corps_id: function(newVal,oldVal) {
      this.selected = null;
      this.$emit("input", this.selected);
    }
  },
  computed: {
    ...mapGetters({
      floors: 'floors/inventory',
    }),
    filtered: function() {
      if (this.corps_id) {
        return this.floors.filter((o) => {
          return this.corps_id == o.corps_id;
        })
      }else{
        return this.floors;
      }
    }
  },
  methods: {
    ...mapActions({
      getInventory: 'floors/inventory',
    })
  },
};
</script>
