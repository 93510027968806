import { index, indexbunk, show, store, update, destroy, inventory} from '../../../api/schedulesCabinet';

export const actions = {

    index({commit}, params = {}) {
        return new Promise((resolve, reject) => {
            index(params).then(res => {
                commit("SET_LIST", res.data.data.patientSchedules.data);
                commit("SET_EVENTS", res.data.data.patientSchedules.data);
                commit("UPDATE_PAGINATION",{key: 'total', value: res.data.data.patientSchedules.pagination.total});
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    indexbunk({commit}, params = {}) {
        return new Promise((resolve, reject) => {
            indexbunk(params).then(res => {
                commit("SET_DATAROOM", res.data.data.hospitalRooms.data);
                commit("UPDATE_PAGINATION",{key: 'total', value: res.data.data.hospitalRooms.pagination.total});
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    inventory({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
          inventory(params)
            .then(response => {
              commit('SET_INVENTORY', response.data.data.patientSchedules.data);
              resolve(response.data);
            })
            .catch(error => {
              reject(error.response.data);
            });
        });
      },    

    show({commit},id) {
        return new Promise((resolve,reject) => {
            show(id).then(res => {
                commit("SET_MODEL",res.data.data.patientSchedule);
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    store({commit},data) {
        return new Promise((resolve,reject) => {
            commit("SET_SAVING",true);
            store(data).then(res => {
                commit("SET_BUNK", res.data.data)
                commit("SET_SAVING",false);
                resolve(res.data)
            }).catch(err => {
                commit("SET_SAVING",false);
                reject(err.response.data)
            })
        })
    },

    update({commit},data) {
        return new Promise((resolve,reject) => {
            update(data).then(res => {
                commit('CHANGE_LIST',res.data.data);
                commit("SET_BUNK", res.data.data)
                commit("SET_SAVING",false);
                resolve(res.data)
            }).catch(err => {
                commit("SET_SAVING",false);
                reject(err.response.data)
            })
        })
    },

    destroy({commit},id) {
        return new Promise((resolve,reject) => {
            destroy(id).then(res => {
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    updateSort({commit}, sort){
        commit("SET_SORT",sort)
    },

    updateFilter({commit}, filter){
        commit('SET_FILTER', JSON.parse( JSON.stringify( filter)))
    },

    updateColumn({commit}, obj){
        commit('UPDATE_COLUMN', obj)
    },

    updatePagination({commit}, pagination){
        commit('UPDATE_PAGINATION', pagination )
    },

    refreshData({commit}){
        return new Promise((resolve, reject) => {
            commit("REFRESH");
            resolve()
        })
    },
    empty({ commit }) {
        return new Promise((resolve, reject) => {
            commit("EMPTY_MODEL");
            resolve()
        })
    },
    setScheduleId({ commit }, id) {
        commit('SET_CURRENT_SCHEDULE_ID', id);
    },
    clearScheduleId({ commit }) {
        commit('EMPTY_CURRENT_SCHEDULE_ID');
    }

}
