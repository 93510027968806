<template>
  <div class="container-fluid no--gutter pl-0 pr-0" :style="{ height: !version ? ' 100px' : '72.5px' }">
    <div class="headerPositionFixed">
      <header class="__header" style="justify-content: space-between">
        <div class="__header-logo">
          <router-link to="/" class="nav-link text-center">
            <img v-if="version && !mode" src="/img/logoDark.png" height="30" />
            <img v-else src="/img/logo.png" height="30" />
          </router-link>
        </div>
        <!-- end logo -->

        <ul v-if="!version" class="nav nav-pills ml-1 iconFont" id="pills-tab" role="tablist">
          <li class="nav-item clear-tabs" @click="changeCurrentTab('pills-main')" v-can-or="[
            'home.active_patients',
            'home.patients',
            'home.orders',
            'home.patient_graph',
            'home.cost_transaction_graph',
            'home.transaction_graph',
            'home.order_graph',
          ]">
            <router-link :to="{ path: '/' }" class="nav-link text-center" :class="{
              active: this.current_tab == 'pills-main',
            }">
              <i class="flaticon-monitor"/>
              Dashboard
            </router-link>
          </li>

          <li class="nav-item clear-tabs" v-can-or="['schedules']" @click="changeCurrentTab('pills-schedules')">
            <router-link class="nav-link text-center" :to="{ name: 'schedules.index' }" aria-selected="false" :class="{
              active: this.current_tab == 'pills-schedules',
            }">
              <i class="flaticon-stethoscope"/>
              {{ $t("message.schedule") }}
            </router-link>
          </li>

          <li class="nav-item" v-can-or="[
            'patients.index',
            'orders',
            'printCenter.index',
            'printCenter.printCenter',
          ]">
            <a class="nav-link text-center" id="pills-sevrvices-tab" data-toggle="pill" href="#pills-sevrvices" role="tab"
              aria-controls="pills-sevrvices" aria-selected="false" @click="changeCurrentTab('pills-sevrvices')" :class="{
                active: this.current_tab == 'pills-sevrvices',
              }">
              <i class="flaticon-medical"/>
              {{ $t("message.patients") }}
            </a>
          </li>

          <li class="nav-item" v-can-or="['doctors_report', 'partner_doctors']">
            <a class="nav-link text-center" id="report-doctors-tab" data-toggle="pill" href="#report-doctors" role="tab"
              aria-controls="report-doctors" aria-selected="false" @click="changeCurrentTab('report-doctors')" :class="{
                active: this.current_tab == 'report-doctors',
              }">
              <i class="flaticon-medical"/>
              {{ $t("message.reports") }}
            </a>
          </li>

          <li class="nav-item" v-can-or="[
            'payments',
            'cost_transactions',
            'currencies',
            'payment_types',
            'costs',
            'contracts',
          ]">
            <a class="nav-link text-center" id="pills-cashiers-tab" data-toggle="pill" href="#pills-cashiers" role="tab"
              aria-controls="pills-cashiers" aria-selected="false" @click="changeCurrentTab('pills-cashiers')" :class="{
                active: this.current_tab == 'pills-cashiers',
              }">
              <i class="flaticon-wallet"/>
              {{ $t("message.cashbox") }}
            </a>
          </li>

          <li class="nav-item" v-can-or="[
            'warehouseProducts',
            'requirements',
            'measurements',
            'warehouses',
            'write_offs',
            'warehouses',
            'currencies',
            'categories',
            'suppliers',
            'products',
            'statuses',
          ]">
            <a class="nav-link text-center" id="pills-Warehouses-tab" data-toggle="pill" href="#pills-Warehouses"
              role="tab" aria-controls="pills-Warehouses" aria-selected="false"
              @click="changeCurrentTab('pills-Warehouses')" :class="{
                active: this.current_tab == 'pills-Warehouses',
              }">
              <i class="flaticon-report"/>
              {{ $t("message.warehouse") }}
            </a>
          </li>

          <li class="nav-item" v-can-or="['users', 'roles', 'permissions', 'rooms']">
            <a class="nav-link text-center" id="pills-usage-tab" data-toggle="pill" href="#pills-usage" role="tab"
              aria-controls="pills-usage" aria-selected="false" @click="changeCurrentTab('pills-usage')" :class="{
                active: this.current_tab == 'pills-usage',
              }">
              <i class="flaticon-staff"/>
              {{ $t("message.actions") }}
            </a>
          </li>

          <li class="nav-item" v-can-or="['documentations.index', 'categoryFormTemplates']">
            <!-- <li class="nav-item" v-can-or="['form_templates','category_form_templates']"> -->
            <a class="nav-link text-center" id="pills-form-templates-tab" data-toggle="pill" href="#pills-form-templates"
              role="tab" aria-controls="pills-form-templates" aria-selected="false"
              @click="changeCurrentTab('pills-form-templates')" :class="{
                active: this.current_tab == 'pills-form-templates',
              }">
              <i class="flaticon-prescription"></i>
              {{ $t("message.forms") }}
            </a>
          </li>

          <li class="nav-item" v-can-or="[
            'doctor_cabinet.patients',
            'doctor_cabinet.index',
            'doctor_cabinet.AllPatients',
            'doctor_cabinet.outsource',
          ]">
            <a class="nav-link text-center" id="pills-doctorCabinet-tab" data-toggle="pill" href="#pills-doctorCabinet"
              role="tab" aria-controls="pills-doctorCabinet" aria-selected="false"
              @click="changeCurrentTab('pills-doctorCabinet')" :class="{
                active: this.current_tab == 'pills-doctorCabinet',
              }">
              <i class="flaticon-report"></i>
              {{ $t("message.my_office") }}
            </a>
          </li>

          <li class="nav-item" v-can-or="[
            'laboratory_cabinet.patients',
            'laboratory_cabinet.index',
            'laboratory_cabinet.AllPatients',
          ]">
            <a class="nav-link text-center" id="pills-laboratoryCabinet-tab" data-toggle="pill"
              href="#pills-laboratoryCabinet" role="tab" aria-controls="pills-laboratoryCabinet" aria-selected="false"
              @click="changeCurrentTab('pills-laboratoryCabinet')" :class="{
                active: this.current_tab == 'pills-laboratoryCabinet',
              }">
              <i class="flaticon-report"></i>
              {{ $t("message.my_laboratory") }}
            </a>
          </li>

          <li class="nav-item" v-can-or="['printCenter.index']">
            <a class="nav-link text-center" id="pills-printCenterMenu-tab" data-toggle="pill"
              href="#pills-printCenterMenu" role="tab" aria-controls="pills-printCenterMenu" aria-selected="false"
              @click="changeCurrentTab('pills-printCenterMenu')" :class="{
                active: this.current_tab == 'pills-printCenterMenu',
              }">
              <i class="flaticon-product"></i>
              {{ $t("message.printing") }}
            </a>
          </li>

          <li class="nav-item clear-tabs" v-can="'hospitals.index'" @click="changeCurrentTab('pills-hospital')">
            <router-link class="nav-link text-center" :to="{ name: 'hospital.index' }" aria-selected="false" :class="{
              active: this.current_tab == 'pills-hospital',
            }">
              <i class="flaticon-report"></i>
              {{ $t("message.hospital") }}
            </router-link>
          </li>

          <li class="nav-item clear-tabs" v-can-or="[
            'work_times',
            'settings',
            'socials',
            'tags',
            'specialties',
            'category_services',
            'services',
            'partner_doctor',
            'hours',
            'conditions',
          ]">
            <a class="nav-link text-center" id="pills-settings-tab" data-toggle="pill" href="#pills-settings" role="tab"
              aria-controls="pills-settings" aria-selected="false" @click="changeCurrentTab('pills-settings')" :class="{
                active: this.current_tab == 'pills-settings',
              }">
              <i class="flaticon-settings"/>
              {{ $t("message.settings") }}
            </a>
          </li>
        </ul>
        <!-- end top menu -->

        <div class="navberRight d-flex align-items-center">
          <div class="mobile-open-menu mobile" @click="toggleMobileMenu()">
            <i class="el-icon-menu"/>
          </div>
          <!-- Theme -->
          <el-tooltip class="item" effect="dark" :content="$t('message.theme_setting')" placement="bottom-end">
            <el-button @click="themeDrawer = true" :type="version ? '' : 'primary'">
              <i class="fa-solid fa-image"/>
            </el-button>
          </el-tooltip>

          <el-tooltip effect="dark" :content="$t('message.new_order')" placement="bottom-end">
            <el-button v-can="'orders.create'" :type="version ? '' : 'primary'" @click="drawerOrder = true">
              <i class="fa-solid fa-plus"/>
            </el-button>
          </el-tooltip>

          <div v-if="env_language == 1">
            <el-dropdown trigger="click" class="flag" @command="handleCommandLangChange">
              <span class="el-dropdown-link">
                <img v-if="$root.$i18n.locale == 'tr'" src="/img/flag/turkey.svg" alt class="flag_img" />
                <img v-if="$root.$i18n.locale == 'uz'" src="/img/flag/uzbekistan.svg" alt class="flag_img" />
                <img v-if="$root.$i18n.locale == 'ru'" src="/img/flag/russia.svg" alt class="flag_img" />
                <img v-if="$root.$i18n.locale == 'en'" src="/img/flag/UK.svg" alt class="flag_img" />
                <i class="el-icon-arrow-down el-icon--right"/>
              </span>
              <el-dropdown-menu slot="dropdown" class="flag_ul">
                <el-dropdown-item command="tr" v-if="$root.$i18n.locale != 'tr'">
                  <img src="/img/flag/turkey.svg" alt class="flag_img flag_img2" />
                  <span>Turkey</span>
                </el-dropdown-item>
                <el-dropdown-item command="uz" v-if="$root.$i18n.locale != 'uz'">
                  <img src="/img/flag/uzbekistan.svg" alt class="flag_img flag_img2" />
                  <span>Uzbekistan</span>
                </el-dropdown-item>
                <el-dropdown-item command="ru" v-if="$root.$i18n.locale != 'ru'">
                  <img src="/img/flag/russia.svg" alt class="flag_img flag_img2" />
                  <span>Russia</span>
                </el-dropdown-item>
                <el-dropdown-item command="en" v-if="$root.$i18n.locale != 'en'">
                  <img src="/img/flag/UK.svg" alt class="flag_img flag_img2" />
                  <span>English</span>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <div class="dropdown">
            <div v-if="!version" class="avatar--settings dropdown-toggle" id="dropdownMenuButton" data-toggle="dropdown"
              aria-haspopup="true" aria-expanded="false">
              <div class="avatar--settings-descr">
                <p class="avatar--settings-name">
                  {{ username }}
                </p>
                <span>{{ phone }}</span>
              </div>
            </div>
            <div class="avatar" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              {{ username ? username.slice(0, 1) : "Z" }}
            </div>
            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">

              <div class="dropdown-item user-info">
                <p>{{ username }} ( {{ current_branch ? current_branch.name : null }} )</p>
                <p>{{ phone }}</p>
              </div>
              <a class="dropdown-item" @click="openDialog">{{
                  $t("message.change_branch")
                }}</a>
              <a class="dropdown-item" @click="logout">{{
                $t("message.log_out")
              }}</a>
            </div>
          </div>
        </div>
      </header>
      <!-- end header -->

      <el-dialog
          :title="$t('message.choose_branch')"
          :visible.sync="branchDialogVisible"
          width="20%"
          :append-to-body="true"
      >
        <div class="za_btn block-layout">
          <el-select
              v-if="owner_branches.length > 1"
              v-model="owner_branch_id"
              :placeholder="$t('message.choose_owner_branch')"
              class="select-with-margin"
              clearable
          >
            <el-option
                v-for="owner_branch in owner_branches"
                :key="owner_branch.owner_branch_id"
                :label="owner_branch.owner_branch.name"
                :value="owner_branch.owner_branch_id"
            ></el-option>
          </el-select>
          <template v-else-if="owner_branches.length === 1">
            <span>{{ owner_branches[0].name }}</span>
          </template>

          <el-select
              v-if="user_branches && user_branches.length > 1"
              v-model="branch_id"
              :placeholder="$t('message.choose_branch')"
              class="select-with-margin"
          >
            <el-option
                v-for="branch in user_branches"
                :key="branch.id"
                :label="branch.name"
                :value="branch.id"
            ></el-option>
          </el-select>
          <template v-if="user_branches.length === 1">
            <span>{{ branches[0].name }}</span>
          </template>

          <el-button
              v-if="branch_id"
              @click="setBranch()"
              type="success"
              icon="el-icon-edit"
              class="button-with-margin"
          >
            {{ $t('message.receive_') }}
          </el-button>
        </div>
      </el-dialog>
      <!-- tab menu  -->
      <div v-if="!version" class="tab-content" id="pills-tabContent">
        <div class="tab-pane fade show" id="pills-sevrvices" role="tabpanel" aria-labelledby="pills-sevrvices-tab"
          :class="{ active: this.current_tab == 'pills-sevrvices' }">
          <nav class="navbar navbar-expand navbar-light bg-white">
            <div class="collapse navbar-collapse">
              <ul class="navbar-nav">
                <li v-can="'patients.index'">
                  <router-link active-class="active" class="nav-link" :to="{ name: 'patients.index' }">{{
                    $t("message.patients") }}</router-link>
                </li>
                <li v-can="'orders.index'">
                  <router-link active-class="active" class="nav-link" :to="{ name: 'orders.index' }">{{
                    $t("message.orders") }}</router-link>
                </li>
                <li>
                  <router-link active-class="active" class="nav-link" :to="{ name: 'orderServiceTerm.index' }">{{
                    $t("message.orderServiceTerm") }}</router-link>
                </li>
                <li v-can="'printCenter.printCenter'">
                  <router-link active-class="active" class="nav-link" :to="{ name: 'printCenterList' }">{{
                    $t("message.conclusions_history") }}</router-link>
                </li>
              </ul>
            </div>
          </nav>
        </div>

        <div class="tab-pane fade show" id="report-doctors" role="tabpanel" aria-labelledby="report-doctors-tab"
          :class="{ active: this.current_tab == 'report-doctors' }">
          <nav class="navbar navbar-expand navbar-light bg-white">
            <div class="collapse navbar-collapse">
              <ul class="navbar-nav">
                <li>
                  <router-link active-class="active" class="nav-link" :to="{ name: 'reportDoctors/index' }">{{
                    $t("message.report_doctors") }}</router-link>
                </li>

                <li>
                  <router-link active-class="active" class="nav-link" :to="{
                    name: 'partnerDoctorReport.index',
                  }">{{ $t("message.report_partner_doctors") }}</router-link>
                </li>

                <li>
                  <router-link active-class="active" class="nav-link" :to="{
                    name: 'partnerClinicReport.index',
                  }">{{ $t("message.report_partner_clinics") }}</router-link>
                </li>

                <li>
                  <router-link active-class="active" class="nav-link" :to="{ name: 'paymentReport' }">{{
                    $t("message.payment_reports") }}</router-link>
                </li>
                <!-- <li>
                  <router-link
                    active-class="active"
                    class="nav-link"
                    :to="{ name: 'remainderReport' }"
                    >{{ $t("message.remainder_reports") }}</router-link
                  >
                </li>
                <li>
                  <router-link
                    active-class="active"
                    class="nav-link"
                    :to="{ name: 'patientAgeReport' }"
                    >{{ $t("message.patient_age_reports") }}</router-link
                  >
                </li> -->
                <li>
                  <router-link active-class="active" class="nav-link" :to="{ name: 'patientResidenceReport' }">{{
                    $t("message.patientResidenceReport") }}</router-link>
                </li>
                <li>
                  <router-link active-class="active" class="nav-link" :to="{ name: 'patientServiceReport' }">{{
                    $t("message.patientServiceReport") }}</router-link>
                </li>

                <li>
                  <router-link active-class="active" class="nav-link" :to="{
                    name: 'dailyProcessReport.index',
                  }">{{ $t("message.dailyProcessReport") }}</router-link>
                </li>

                <li>
                  <router-link active-class="active" class="nav-link" :to="{
                    name: 'doctorProcessReport.index',
                  }">{{ $t("message.doctorProcessReport") }}</router-link>
                </li>

                <li v-can-or="[
                  'excelReports.1',
                  'excelReports.2',
                  'excelReports.3',
                  'excelReports.4'
                ]">
                  <router-link active-class="active" class="nav-link" :to="{
                    name: 'excelReports.index',
                  }">{{ $t("message.excelReport") }}</router-link>
                </li>
              </ul>
            </div>
          </nav>
        </div>

        <div class="tab-pane fade show" id="pills-cashiers" role="tabpanel" aria-labelledby="pills-cashiers-tab"
          :class="{ active: this.current_tab == 'pills-cashiers' }">
          <nav class="navbar navbar-expand navbar-light bg-white">
            <div class="collapse navbar-collapse">
              <ul class="navbar-nav">
                <li v-can="'payments.index'">
                  <router-link active-class="active" class="nav-link" :to="{ name: 'payments.index' }">{{
                    $t("message.payments") }}</router-link>
                </li>
                <!-- <li>
                  <router-link
                    active-class="active"
                    class="nav-link"
                    :to="{ name: 'contracts.index' }"
                    >{{ $t("message.contracts") }}</router-link
                  >
                </li> -->
                <li v-can="'payments.index'">
                  <router-link active-class="active" class="nav-link" :to="{ name: 'payments.report' }">{{
                    $t("message.payment_reports") }}</router-link>
                </li>
                <li v-can="'cost_transactions.index'">
                  <router-link active-class="active" class="nav-link" :to="{ name: 'costTransactions.index' }">{{
                    $t("message.payments_for_expenses") }}
                  </router-link>
                </li>
                <li v-can="'currencies.index'">
                  <router-link active-class="active" class="nav-link" :to="{ name: 'currencies.index' }">{{
                    $t("message.currency") }}</router-link>
                </li>
                <li v-can="'payment_types.index'">
                  <router-link active-class="active" class="nav-link" :to="{ name: 'paymentTypes.index' }">{{
                    $t("message.payment_type") }}</router-link>
                </li>
                <li v-can="'costs.index'">
                  <router-link active-class="active" class="nav-link" :to="{ name: 'costs.index' }">{{
                    $t("message.flow_types") }}</router-link>
                </li>
                <li v-can="'costs.index'">
                  <router-link active-class="active" class="nav-link" :to="{ name: 'paymentBonus.index' }">
                    {{ $t("message.paymentBonusP") }}
                  </router-link>
                </li>
                <!-- <li v-can="'costs.index'">
                  <router-link
                    active-class="active"
                    class="nav-link"
                    :to="{ name: 'scores.index' }"
                  >
                    {{ $t("message.scores") }}
                  </router-link>
                </li> -->

                <li v-can="'costs.index'">
                  <router-link active-class="active" class="nav-link" :to="{ name: 'transactionScores.index' }">
                    {{ $t("message.transactionScores") }}
                  </router-link>
                </li>

                <li>
                  <router-link active-class="active" class="nav-link" :to="{ name: 'invoice.index' }">
                    {{ $t("message.invoice") }}
                  </router-link>
                </li>

                <!-- <li v-if="cacheHospital == 1">
                  <router-link
                    active-class="active"
                    class="nav-link"
                    :to="{
                      name: 'cashierStationary.index',
                    }"
                    >{{ $t("message.stasionary_cashier") }}</router-link
                  >
                </li> -->
              </ul>
            </div>
          </nav>
        </div>

        <div class="tab-pane fade show" id="pills-usage" role="tabpanel" aria-labelledby="pills-usage-tab"
          :class="{ active: this.current_tab == 'pills-usage' }">
          <nav class="navbar navbar-expand navbar-light bg-white">
            <div class="collapse navbar-collapse" id="navbarNav">
              <ul class="navbar-nav">
                <li v-if="env_user == 1" v-can="'users.index'">
                  <router-link active-class="active" class="nav-link" :to="{ name: 'users.index' }">{{ $t("message.users")
                  }}</router-link>
                </li>
                <li v-can="'roles.index'">
                  <router-link active-class="active" class="nav-link" :to="{ name: 'roles.index' }">{{ $t("message.roles")
                  }}</router-link>
                </li>
                <li v-can="'permissions.index'">
                  <router-link active-class="active" class="nav-link" :to="{ name: 'permissions.index' }">{{
                    $t("message.access_rights") }}</router-link>
                </li>
                <!-- <li v-can="'printAudit.index'"> -->
                <li>
                  <router-link active-class="active" class="nav-link" :to="{ name: 'printAudit' }">{{
                    $t("message.print_audit") }}</router-link>
                </li>
              </ul>
            </div>
          </nav>
        </div>

        <div class="tab-pane fade show" id="pills-form-templates" role="tabpanel"
          aria-labelledby="pills-form-templates-tab" :class="{
            active: this.current_tab == 'pills-form-templates',
          }">
          <nav class="navbar navbar-expand navbar-light bg-white">
            <div class="collapse navbar-collapse" id="navbarNav">
              <ul class="navbar-nav">
                <li v-can="'categoryFormTemplates.index'">
                  <!-- <li  v-can="'category_form_templates.index'"> -->
                  <router-link active-class="active" class="nav-link" :to="{ name: 'categoryFormTemplates' }">{{
                    $t("message.category_template") }}</router-link>
                </li>
                <li v-can="'documentations.index'">
                  <router-link active-class="active" class="nav-link" :to="{ name: 'documentationList' }">{{
                    $t("message.conclusion_templates") }}</router-link>
                </li>
                <li>
                  <!-- <router-link active-class="active" class="nav-link" :to="{name: 'components'}">Компоненты</router-link> -->
                </li>
              </ul>
            </div>
          </nav>
        </div>

        <div class="tab-pane fade show" id="pills-printCenterMenu" role="tabpanel"
          aria-labelledby="pills-printCenterMenu-tab" :class="{
            active: this.current_tab == 'pills-printCenterMenu',
          }">
          <nav class="navbar navbar-expand navbar-light bg-white">
            <div class="collapse navbar-collapse">
              <ul class="navbar-nav">
                <li v-can="'printCenter.index'">
                  <router-link active-class="active" class="nav-link" :to="{ name: 'printCenter' }">{{
                    $t("message.print_center") }}</router-link>
                </li>
                <li v-can="'printCenter.index'">
                  <router-link active-class="active" class="nav-link" :to="{ name: 'printHistory' }">{{
                    $t("message.print_hitory") }}</router-link>
                </li>
              </ul>
            </div>
          </nav>
        </div>

        <div class="tab-pane fade show" id="pills-laboratory" role="tabpanel" aria-labelledby="pills-laboratory-tab"
          :class="{
            active: this.current_tab == 'pills-laboratory',
          }">
          <nav class="navbar navbar-expand navbar-light bg-white">
            <div class="collapse navbar-collapse">
              <ul class="navbar-nav">
                <li v-can="'printCenter.index'">
                  <router-link active-class="active" class="nav-link" :to="{ name: 'normaLaboratory' }">
                    {{ $t("message.normaLaboratory") }}
                  </router-link>
                </li>

                <li v-can="'printCenter.index'">
                  <router-link active-class="active" class="nav-link" :to="{ name: 'normaCost' }">
                    {{ $t("message.normaCost") }}
                  </router-link>
                </li>

                <li>
                  <router-link active-class="active" class="nav-link" :to="{ name: 'resources' }">
                    {{ $t("message.resourcesText") }}
                  </router-link>
                </li>

                <li>
                  <router-link active-class="active" class="nav-link" :to="{ name: 'conResources' }">
                    {{ $t("message.conResourcesText") }}
                  </router-link>
                </li>
              </ul>
            </div>
          </nav>
        </div>

        <div class="tab-pane fade show" id="pills-doctorCabinet" role="tabpanel" aria-labelledby="pills-doctorCabinet-tab"
          :class="{
            active: this.current_tab == 'pills-doctorCabinet',
          }">
          <nav class="navbar navbar-expand navbar-light bg-white">
            <div class="collapse navbar-collapse" id="navbarNav">
              <ul class="navbar-nav">
                <li v-can="'doctor_cabinet.patients'">
                  <router-link active-class="active" class="nav-link" :to="{
                    name: 'doctor-cabinet.informal-patients',
                  }">{{ $t("message.my_informal_patients") }}</router-link>
                </li>
                <li v-can="'doctor_cabinet.patients'">
                  <router-link active-class="active" class="nav-link" :to="{
                    name: 'doctor-cabinet.patients',
                  }">{{ $t("message.my_patients") }}</router-link>
                </li>
                <li v-can="'doctor_cabinet.AllPatients'">
                  <router-link active-class="active" class="nav-link" :to="{
                    name: 'doctor-cabinet.AllPatients',
                  }">{{ $t("message.my_conclusions") }}</router-link>
                </li>
                <li v-can="'doctor_cabinet.outsource'">
                  <router-link active-class="active" class="nav-link" :to="{
                    name: 'doctor-cabinet.outsource',
                  }">{{ $t("message.outsource") }}</router-link>
                </li>
                <li>
                  <router-link active-class="active" class="nav-link" :to="{
                    name: 'doctor-cabinet.patientMedicine',
                  }">Процедурный лист
                  </router-link>
                </li>
                <li v-can="'doctor_cabinet.AllPatients'">
                  <router-link active-class="active" class="nav-link" :to="{
                    name: 'doctor-cabinet.patientMedicineShow',
                  }">
                    Процедурная
                  </router-link>
                </li>
              </ul>
            </div>
          </nav>
        </div>

        <div class="tab-pane fade show" id="pills-laboratoryCabinet" role="tabpanel"
          aria-labelledby="pills-laboratoryCabinet-tab" :class="{
            active: this.current_tab == 'pills-laboratoryCabinet',
          }">
          <nav class="navbar navbar-expand navbar-light bg-white">
            <div class="collapse navbar-collapse" id="navbarNav">
              <ul class="navbar-nav">
                <li>
                  <router-link active-class="active" class="nav-link" :to="{
                    name: 'laboratory-cabinet.informal-patients',
                  }">{{
                        $t("message.my_informal_laboratory_patients")
                      }}</router-link>
                </li>
                <li>
                  <router-link active-class="active" class="nav-link" :to="{
                        name: 'laboratory-cabinet.patients',
                      }">{{ $t("message.my_patients") }}</router-link>
                </li>
                <li>
                  <router-link active-class="active" class="nav-link" :to="{
                    name: 'laboratory-cabinet.AllPatients',
                  }">{{ $t("message.my_conclusions") }}</router-link>
                </li>
              </ul>
            </div>
          </nav>
        </div>

        <div class="tab-pane fade show" id="pills-Warehouses" role="tabpanel" aria-labelledby="pills-Warehouses-tab"
          :class="{ active: this.current_tab == 'pills-Warehouses' }">
          <nav class="navbar navbar-expand navbar-light bg-white">
            <div class="collapse navbar-collapse" id="navbarNav">
              <ul class="navbar-nav">
                <li>
                  <router-link active-class="active" class="nav-link" :to="{ name: 'buyReadyProducts.index' }">{{
                    $t("message.procurement") }}</router-link>
                </li>
                <li>
                  <router-link active-class="active" class="nav-link" :to="{ name: 'stockBunk.index' }">{{
                      $t("message.warehouses_0") }}</router-link>
                </li>
                <li>
                  <router-link active-class="active" class="nav-link" :to="{ name: 'requirement.index' }">{{
                      $t("message.requirement") }}</router-link>
                </li>
                <li>
                  <router-link active-class="active" class="nav-link" :to="{ name: 'moving.index' }">{{
                    $t("message.movement") }}</router-link>
                </li>
                <li>
                  <router-link active-class="active" class="nav-link" :to="{ name: 'writeOffs.index' }">{{
                    $t("message.write_offs") }}</router-link>
                </li>
                <li>
                  <router-link active-class="active" class="nav-link" :to="{ name: 'stocksWarehouses.index' }">{{
                    $t("message.remainder") }}</router-link>
                </li>
                <li>
                  <router-link active-class="active" class="nav-link" :to="{ name: 'stocksWarehouses.consuption' }">Расход
                    материали</router-link>
                </li>
                <li>
                  <router-link active-class="active" class="nav-link" :to="{
                    name: 'ProductsWarehouses.index',
                  }">{{ $t("message.products") }}</router-link>
                </li>
                <li>
                  <router-link active-class="active" class="nav-link" :to="{
                    name: 'stocksSryaWarehouses.index',
                  }">{{ $t("message.warehouses") }}</router-link>
                </li>
                <li>
                  <router-link active-class="active" class="nav-link" :to="{
                    name: 'currenciesWarehouses.index',
                  }">{{ $t("message.currency") }}</router-link>
                </li>
                <li>
                  <router-link active-class="active" class="nav-link" :to="{ name: 'groupsWarehouses.index' }">{{
                    $t("message.groups") }}</router-link>
                </li>
                <li>
                  <router-link active-class="active" class="nav-link" :to="{
                    name: 'measurementsWarehouses.index',
                  }">{{ $t("message.measurements") }}</router-link>
                </li>
                <li>
                  <router-link active-class="active" class="nav-link" :to="{
                    name: 'warehouseProvider.index',
                  }">{{ $t("message.suppliers") }}</router-link>
                </li>
                <li>
                  <router-link active-class="active" class="nav-link" :to="{
                    name: 'contractWarehouses.index',
                  }">{{ $t("message.suppliers_aggrement") }}</router-link>
                </li>
                <li>
                  <router-link active-class="active" class="nav-link" :to="{ name: 'statuses.index' }">{{
                    $t("message.status") }}</router-link>
                </li>
                <li v-if="need_pill == 1">
                  <router-link active-class="active" class="nav-link" :to="{ name: 'neededPills.index' }">{{
                    $t("message.medicines_on") }}</router-link>
                </li>
              </ul>
            </div>
          </nav>
        </div>

        <div class="tab-pane fade show" id="pills-settings" role="tabpanel" aria-labelledby="pills-settings-tab"
          :class="{ active: this.current_tab == 'pills-settings' }">
          <nav class="navbar navbar-expand scroll-khan navbar-light bg-white">
            <div class="collapse navbar-collapse" id="navbarNav">
              <ul class="navbar-nav">
                <li v-can="'work_times.index'">
                  <router-link active-class="active" class="nav-link" :to="{ name: 'workTimes.index' }">{{
                    $t("message.work_schedule") }}</router-link>
                </li>
                <!-- <li  v-can="'settings.filial'">
                                        <router-link active-class="active" class="nav-link" :to="{name: 'filial'}">Филиалы</router-link>
                                    </li> -->
                <!-- <li v-can="'settings.printed_form'">
                                        <router-link active-class="active" class="nav-link" :to="{name: 'printedForm'}">Печатные форма </router-link>
                                    </li>
                                    <li v-can="'settings.documentation'">
                                        <router-link active-class="active" class="nav-link" :to="{name: 'documentation'}">Формы Документов</router-link>
                                    </li> -->
                <!-- <li v-can="'settings.documentation'">
                                        <router-link active-class="active" class="nav-link" :to="{name: 'documentationEditor'}">Формы Документов Editor</router-link>
                                    </li>
                                    <li v-can="'socials.index'">
                                        <router-link active-class="active" class="nav-link" :to="{name: 'socials.index'}">Источник</router-link>
                                    </li>
                                    <li v-can="'tags.index'">
                                        <router-link active-class="active" class="nav-link" :to="{name: 'tags.index'}">Теги</router-link>
                                    </li> -->
                <li v-can="'specialties.index'">
                  <router-link active-class="active" class="nav-link" :to="{ name: 'specialties.index' }">{{
                    $t("message.specialities") }}</router-link>
                </li>
                <li v-can="'rooms.index'">
                  <router-link active-class="active" class="nav-link" :to="{ name: 'rooms.index' }">{{ $t("message.rooms")
                  }}</router-link>
                </li>
                <!-- <li v-can="'category_services.index'">
                                        <router-link active-class="active" class="nav-link" :to="{name: 'categoryServices.index'}">Категория услуги</router-link>
                                    </li> -->
                <li v-can="'services.index'">
                  <router-link active-class="active" class="nav-link" :to="{ name: 'services.index' }">{{
                    $t("message.services") }}</router-link>
                </li>
                <!-- <li v-can="'services.price_list'">
                                        <router-link active-class="active" class="nav-link" :to="{name: 'services.priceList'}">Прайс лист</router-link>
                                    </li> -->
                <li v-can="'partner_doctors.index'">
                  <router-link active-class="active" class="nav-link" :to="{ name: 'partnerDoctor.index' }">{{
                    $t("message.partner_doctor") }}</router-link>
                </li>
                <li v-can="'partner_doctors.index'">
                  <router-link active-class="active" class="nav-link" :to="{ name: 'partnerClinic.index' }">{{
                    $t("message.partner_clinic") }}</router-link>
                </li>
                <li>
                  <router-link active-class="active" class="nav-link" :to="{ name: 'ownerClinic.index' }">{{
                    $t("message.owner_clinic") }}</router-link>
                </li>
                <li v-can="'hours.index'">
                  <router-link active-class="active" class="nav-link" :to="{ name: 'hours.index' }">{{ $t("message.time")
                  }}</router-link>
                </li>
                <li v-can="'conditions.index'">
                  <router-link active-class="active" class="nav-link" :to="{ name: 'conditions.index' }">{{
                    $t("message.condition") }}</router-link>
                </li>
                <!-- <li v-can="'organizations.index'">
                  <router-link
                    active-class="active"
                    class="nav-link"
                    :to="{ name: 'organizations.index' }"
                    >{{ $t("message.organization") }}</router-link
                  >
                </li> -->
                <li v-if="env_state == 1" v-can="'states.index'">
                  <router-link active-class="active" class="nav-link" :to="{ name: 'states.index' }">{{
                    $t("message.countries") }}</router-link>
                </li>
                <li v-if="env_region == 1" v-can="'regions.index'">
                  <router-link active-class="active" class="nav-link" :to="{ name: 'region.index' }">{{
                    $t("message.region") }}</router-link>
                </li>

                <li v-if="env_city == 1" v-can="'cities.index'">
                  <router-link active-class="active" class="nav-link" :to="{ name: 'cities.index' }">{{
                    $t("message.cities") }}</router-link>
                </li>
                <li>
                  <router-link active-class="active" class="nav-link" :to="{ name: 'district.index' }">{{
                    $t("message.district") }}</router-link>
                </li>
                <!-- <li v-can="'document_types.index'">
                  <router-link
                    active-class="active"
                    class="nav-link"
                    :to="{ name: 'documentTypes.index' }"
                    >{{ $t("message.type_documents") }}</router-link
                  >
                </li> -->
                <!-- <li v-if="env_citizenship == 1" v-can="'citizenships.index'">
                  <router-link
                    active-class="active"
                    class="nav-link"
                    :to="{ name: 'citizenship.index' }"
                    >{{ $t("message.citizenship") }}</router-link
                  >
                </li> -->
                <!-- <li v-if="env_nationality == 1">
                  <router-link
                    active-class="active"
                    class="nav-link"
                    :to="{ name: 'nationality.index' }"
                    >{{ $t("message.nationality") }}</router-link
                  >
                </li>
                <li v-if="letterHead == 1">
                  <router-link
                    active-class="active"
                    class="nav-link"
                    :to="{ name: 'patientAge.index' }"
                    >{{ $t("message.age") }}</router-link
                  >
                </li> -->

                <li>
                  <router-link active-class="active" class="nav-link" :to="{ name: 'formLogo.index' }">{{
                    $t("message.blank_head") }}
                  </router-link>
                </li>

                <li>
                  <router-link active-class="active" class="nav-link" :to="{ name: 'discountPatient.index' }">{{
                    $t("message.discounts") }}</router-link>
                </li>

                <li>
                  <router-link active-class="active" class="nav-link" :to="{ name: 'express.index' }">{{
                    $t("message.expresses") }}</router-link>
                </li>

                <li>
                  <router-link active-class="active" class="nav-link" :to="{ name: 'process.index' }">{{
                    $t("message.processes") }}</router-link>
                </li>
                <li>
                  <router-link active-class="active" class="nav-link" :to="{ name: 'department.index' }">{{
                    $t("message.department") }}</router-link>
                </li>
                <li>
                  <router-link active-class="active" class="nav-link" :to="{ name: 'material.index' }">{{
                    $t("message.material") }}</router-link>
                </li>
                <li>
                  <router-link active-class="active" class="nav-link" :to="{ name: 'sickNumber.index' }">{{
                    $t("message.sickNumber") }}</router-link>
                </li>

                <li>
                  <router-link active-class="active" class="nav-link" :to="{ name: 'doctorSign.index' }">{{
                    $t("message.doctorSign") }}</router-link>
                </li>
                <li>
                  <router-link class="nav-link" :to="{ name: 'socials.index' }">{{ $t("message.source") }}</router-link>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
      <!-- end tab menu -->

      <el-drawer :wrapperClosable="false" class="popups_title" :visible.sync="drawerMassOrder" :drawer="drawerMassOrder"
        :before-close="handleUpdateModalClose" direction="rtl" size="80%" :append-to-body="true">
        <CreateMass ref="addOrder" @c-close="closeDrawer" drawer="drawerMassOrder" :open="drawerMassOrder">
        </CreateMass>
      </el-drawer>

      <el-drawer :wrapperClosable="false" class="popups_title" :visible.sync="drawerOrder" :drawer="drawerOrder"
        :before-close="handleUpdateModalClose" direction="rtl" size="100%" :append-to-body="true"
        @opened="drawerOpened(newOrderRef)">
        <newOrderAll :ref="newOrderRef" @c-close="closeDrawer" drawer="drawerOrder" :open="drawerOrder"></newOrderAll>
        <!-- <CreateNew :ref="newOrderRef" @c-close="closeDrawer" drawer="drawerOrder" :open="drawerOrder"></CreateNew> -->
      </el-drawer>
      <!-- end modal add patsent  -->
    </div>

    <!-- theme -->
    <el-drawer @opened="handlerThemeDrawer" size="45%" :visible.sync="themeDrawer" :with-header="false">
      <Builder :loading="loading" @close-draw="themeDrawer = false" />
    </el-drawer>
  </div>
</template>

<script>
import { mapGetters, mapState, mapMutations, mapActions } from "vuex";
import CreateNew from "@/views/popup/newOrder";
import newOrderAll from "@/views/popup/newOrderAll";
import CreateMass from "@/views/popup/massOrder";
import Builder from "./Builder.vue";
import languageMixin from "@/utils/mixins/languageMixin";

export default {
  mixins: [languageMixin],
  components: {
    CreateNew,
    newOrderAll,
    CreateMass,
    Builder,
  },
  data() {
    return {
      loading: true,
      themeDrawer: false,
      branchDialogVisible: false,
      branch_id: null,
      user_branches: [],
      owner_branch_id: null,

      // ================================
      drawerOrder: false,
      newOrderRef: "addOrder",
      drawerMassOrder: false,
      cacheHospital: parseInt(process.env.VUE_APP_CACHE_HOSPITAL),
      warehouse: parseInt(process.env.VUE_APP_WAREHOUSE),
      env_state: parseInt(process.env.VUE_APP_STATE),
      env_region: parseInt(process.env.VUE_APP_REGION),
      env_city: parseInt(process.env.VUE_APP_CITY),
      env_citizenship: parseInt(process.env.VUE_APP_CITIZENSHIP),
      env_nationality: parseInt(process.env.VUE_APP_NATIONALITY),
      env_hospital: parseInt(process.env.VUE_APP_HOSPITAL),
      env_user: parseInt(process.env.VUE_APP_USER),
      env_language: parseInt(process.env.VUE_APP_LANGUAGE),
      write_offs: parseInt(process.env.VUE_APP_WRITE_OFFS),
      print_center: parseInt(process.env.VUE_APP_PRINT_CENTER),
      need_pill: parseInt(process.env.VUE_APP_NEED_PILL),
      letterHead: parseInt(process.env.VUE_APP_LETTERHEAD),
    };
  },
  computed: {
    ...mapState({
      version: (state) => state.theme.version,
    }),
    ...mapGetters({
      id: "auth/id",
      username: "auth/name",
      phone: "auth/phone",
      branches: "auth/branches",
      current_branch: "auth/current_branch",
      owner_branches: "auth/owner_branch",
      current_tab: "current_tab",
      mode: "theme/MODE",
    }),
  },
  created() { },
  mounted() {
    $(".clear-tabs").click(function () {
      $(".nav-item>a").removeClass("active");
      $(".tab-pane").removeClass(["show", "active"]);
    });

    const lang = localStorage.getItem("currentLang");
    if (!this.current_branch) {
      this.currentUser();
    }
    if (lang) {
      this.handleCommandLangChange(lang, false);
    }
  },
  watch: {
    owner_branch_id: {
      handler: function () {
        if (this.owner_branch_id != '') {
          this.getDepartments();
        }
      }
    }
  },
  methods: {
    ...mapActions({
      currentUser: "auth/getAuth",
      setUserBranch: "users/setDoctorBranch",
      getOwnerBranchDepartments: "branches/getOwnerBranchDepartments",
    }),
    logout() {
      this.$store
        .dispatch("auth/logout")
        .then((res) => {
          this.$router.push(`/login`);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async openDialog() {
      this.branchDialogVisible = true;
      if (this.owner_branches.length === 1) {
        this.owner_branch_id = this.branches[0].owner_branch_id;
      }
    },
    getDepartments() {
      const query = {
        id: this.owner_branch_id,
      };
      this.getOwnerBranchDepartments(query)
        .then((res) => {
          this.user_branches = res.data.branches;

          if (this.user_branches.length === 1) {
            this.branch_id = this.user_branches[0].id;
            this.setBranch();
          }
        });
    },
    setBranch() {
      const query = {
        user_id: this.id,
        branch_id: this.branch_id
      };
      this.setUserBranch(query)
        .then((res) => {
          this.branchDialogVisible = false;
          this.currentUser();
        });
    },
    toggleMobileMenu() {
      this.$store.commit("TOGGLE_MOBILE_MENU")
    },
    drawerOpened(ref) {
      this.$refs[ref].opened();
    },
    closeDrawer(obj) {
      if (obj.reload) {
      }
      if (obj.drawer) {
        this[obj.drawer] = false;
      }
    },
    handleUpdateModalClose(done) {
      done();
      this.$refs["addOrder"].empty();
      if (this.changeListener) {
        this.$parent.fetchData();
      }
    },
    changeCurrentTab(selectedNav) {
      this.$store.commit("CHANGE_CURRENT_TAB", selectedNav);
    },

    // =====================
    handlerThemeDrawer() {
      setTimeout(() => {
        this.loading = false;
      }, 2500);
    },
  },
};
</script>
<style scoped>
.za_btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px; /* Отступ между элементами */
}
</style>

<style>
.nav-link.active {
  border-bottom: 2px solid #1f75a8;
}

.navbar-nav li .nav-link {
  color: black !important;
}

.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
}

.el-icon-arrow-down {
  font-size: 14px;
}

.dropdown-menu.dropdown-menu-right .el-dropdown-menu.el-popper {
  width: 200px;
}

.el-dropdown-menu.el-popper li a {
  color: black;
  font-weight: bold;
}

.headerPositionFixed {
  position: fixed;
  z-index: 12;
  width: 100%;
}

@media only screen and (max-width: 1650px) {
  .scroll-khan {
    overflow-x: scroll;
  }

  .scroll-khan>.navbar-collapse>.navbar-nav {
    width: 2000px;
  }
}

@media only screen and (max-width: 1450px) {
  .nav li.nav-item {
    min-width: 61px !important;
  }

  .__header {
    padding: 0 0.5rem;
  }

  #pills-tab>li>a {
    font-size: 9px !important;
    padding: 0.5rem 0rem 0.5em !important;
  }
}

@media only screen and (max-width: 1300px) {
  .nav li.nav-item {
    min-width: 61px !important;
  }

  .__header {
    padding: 0 0.5rem;
  }

  #pills-tab>li>a {
    font-size: 9px !important;
    padding: 0.5rem 0rem 0.5em !important;
  }
}
</style>

<style lang="scss" scoped>
.navberRight {
  gap: 13px;

  .el-button {
    margin: 0;
  }
}

.avatar {
  width: 38px;
  height: 38px;
  display: grid;
  place-content: center;
  border-radius: 10px;
  color: #727b89;
  background: #fff;
  border: 1px solid #e8e5dd;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;

  &:hover {
    color: #fff;
    background-color: #0c67c9;
    border-color: #0c67c9;
  }
}
</style>
