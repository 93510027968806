import { sort } from "../properties/sort";
import { pagination } from "../properties/pagination";
import { filter } from "./properties/filter";
import { model } from "./properties/model";
import { i18n } from '@/utils/i18n';

export const mutations = {
    SET_LIST: (state, ownerBranches) => (state.list = ownerBranches),
    SET_FILTER: (state, filter) => (state.filter = filter),
    SET_PAGINATION: (state, pagination) => (state.pagination = pagination),
    SET_SORT: (state, sort) => (state.sort = sort),
    SET_MODEL: (state, model) => {
        state.model = model;
    }, SET_PHONE_NUMBERS: (state, model) => {
        state.phone_numbers = model;
    },
    SET_OWNER_BRANCH_HEADER: (state, model) => {
        state.header_model = model;
    },
    UPDATE_COLUMN: (state, obj) => {
        state.columns[obj.key].show = obj.value
    },
    UPDATE_SORT: (state, sort) => {
        state.sort[sort.column] = sort.order
    },
    UPDATE_PAGINATION: (state, pagination) => {
        state.pagination[pagination.key] = pagination.value
    },
    EMPTY_MODEL: (state) => {
        state.model = JSON.parse(JSON.stringify(model));
    },
    REFRESH: (state) => {
        state.filter = JSON.parse(JSON.stringify(filter));
        state.sort = JSON.parse(JSON.stringify(sort));
        state.pagination = JSON.parse(JSON.stringify(pagination));
    },
    UPDATE_LANG_COLUMN: (state) => {
        state.columns.id.title = "№";
        state.columns.address.title = i18n.t('message.address');
        state.columns.web_site.title = i18n.t('message.web_site');
        state.columns.footer_image.title = i18n.t('message.footer_image');
        state.columns.full_logo.title = i18n.t('message.full_logo');
        state.columns.half_logo.title = i18n.t('message.half_logo');
        state.columns.phone_number.title = i18n.t('message.phone_number');
        state.columns.email.title = i18n.t('message.email');
        state.columns.image.title = i18n.t('message.logo');
    }
};
