import { i18n } from '@/utils/i18n';
export const columnsContact = {
    full_name: {
        title: i18n.t('message.full_name'),
    },
    position: {
        title: i18n.t('message.position'),
    },
    phone: {
        title: i18n.t('message.phone_number'),
    },
    email: {
        title: i18n.t('message.email'),
    },
    description: {
        title: i18n.t('message.description'),
    }
};