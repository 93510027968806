import { sort } from './../properties/sort';
import { pagination } from './../properties/pagination';
import { filter } from './properties/filter';
import { model } from './properties/model';
import { i18n } from '@/utils/i18n';

export const mutations = {
  SET_LIST: (state, list) => {
    state.list = list;
  },
  SET_FILTER: (state, filter) => {
    state.filter = filter;
  },
  SET_SORT: (state, sort) => {
    state.sort = sort;
  },
  SET_MODEL: (state, model) => {
    state.model = model;
  },
  SET_INVENTORY: (state, inventory) => {
    state.inventory = inventory;
  },
  UPDATE_COLUMN: (state, column) => {
    state.columns[column.key].show = column.value;
  },
  UPDATE_SORT: (state, sort) => {
    state.sort = sort;
  },
  UPDATE_PAGINATION: (state, pagination) => {
    state.pagination[pagination.key] = pagination.value;
  },
  EMPTY_MODEL: (state) => {
    state.model = JSON.parse(JSON.stringify(model))
  },
  REFRESH: (state) => {
    state.filter = JSON.parse(JSON.stringify(filter));
    state.sort = JSON.parse(JSON.stringify(sort));
    state.pagination = JSON.parse(JSON.stringify(pagination));
  },
  UPDATE_LANG_COLUMN: (state) => {
      state.columns.id.title = "№";
      state.columns.mainwarehouse_id.title = i18n.t('message.main_warehouses');
      state.columns.warehouse_id.title =  i18n.t('message.warehouse_movement');
      state.columns.created_at.title = i18n.t('message.date_movement');
      state.columns.user_id.title = i18n.t('message.users');
      state.columns.wlec.title =  i18n.t('message.show_drug');
      state.columns.status.title =  i18n.t('message.status');
      state.columns.settings.title = i18n.t('message.settings');
  }
}