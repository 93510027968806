export const model = {
    id:  "",
    number: "",
    date_gos:  "",
    date_injury: "",
    allergy: "",
    diagnos: "",
    doctor_id: "",
    patient_id: "",
    branch_id: "",
    type_hospitalization_id: "",
    purpose_hospitalization_id: "",
    teperatura: "",
    impuls_one: "",
    impuls_two: "",
    status_id: "",
    weight:  "",
    height: "",
    date_register: "",
    blood: "",
    injury_type_id: "",
    created_at: "",
    updated_at: "",
};
