<template>
  <el-select
    :value="selected"
    @input="dispatch"
    :placeholder="plc || $t('message.room')"
    filterable
    clearable
   
  >
    <el-option v-for="item in filtered" :key="item.id" :label="item.name" :value="item.id"></el-option>
  </el-select>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import inventory from '@/utils/mixins/components/inventory';

export default {
  props: ['floor_id', 'hospital_room_id'],
  mixins: [inventory],
  mounted() {
    if (this.hospitalRooms && this.hospitalRooms.length === 0) {
      this.getInventory();
    }
  },
  watch: {
    hospital_room_id:{
       handler: function (val, oldVal) { 
          this.selected = val;
          this.$emit("input", this.selected);
       },
       deep: true,
       immediate: true
    }
  },
  computed: {
    ...mapGetters({
      hospitalRooms: 'hospitalRooms/inventory',
    }),
    filtered: function() {
      if (this.floor_id) {
        return this.hospitalRooms.filter((o) => {
          return this.floor_id == o.floor_id;
        })
      }else{
        return this.hospitalRooms;
      }
    }
  },
  methods: {
    ...mapActions({
      getInventory: 'hospitalRooms/inventory',
    })
  },
};
</script>
