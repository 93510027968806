import { model } from "./properties/model";
import { i18n } from '@/utils/i18n';

export const mutations = {
    SET_MODEL: (state, model) => {
        state.model = model;
    },
    SET_CONCLUSION_LOGO_MODEL: (state, model) => {
        state.conclusion_logo_model = model;
    },
    SET_PHONE_NUMBERS: (state, model) => {
        state.phone_numbers = model;
    },
    EMPTY_MODEL: (state) => {
        state.model = JSON.parse(JSON.stringify(model));
    },
    REFRESH: (state) => {
        state.filter = JSON.parse(JSON.stringify(filter));
        state.sort = JSON.parse(JSON.stringify(sort));
        state.pagination = JSON.parse(JSON.stringify(pagination));
    },
    UPDATE_LANG_COLUMN: (state) => {
        state.columns.id.title = "№";
        state.columns.address.title = i18n.t('message.address');
        state.columns.web_site.title = i18n.t('message.web_site');
        state.columns.footer_image.title = i18n.t('message.footer_image');
        state.columns.full_logo.title = i18n.t('message.full_logo');
        state.columns.half_logo.title = i18n.t('message.half_logo');
        state.columns.phone_number.title = i18n.t('message.phone_number');
        state.columns.email.title = i18n.t('message.email');
        state.columns.image.title = i18n.t('message.logo');
    }
};
