import { sort } from "../properties/sort";
import { filter } from "./properties/filter";
import { pagination } from "../properties/pagination";
import { model } from "./properties/model";
import { i18n } from '@/utils/i18n';

export const mutations = {
    SET_LIST: (state, services) => (state.list = services),
    SET_REPORTS: (state, services) => (state.reports = services),
    SET_FILTER: (state, filter) => (state.filter = filter),
    SET_PAGINATION: (state, pagination) => (state.pagination = pagination),
    SET_SORT: (state, sort) => (state.sort = sort),
    SET_MODEL: (state, model) => (state.model = model),
    UPDATE_COLUMN: (state, obj) => {
        state.columns[obj.key].show = obj.value
    },
    SET_SCORE_REPORTS: (state, data) => {
        state.score = data;
    },

    UPDATE_SORT: (state, sort) => {
        state.sort[sort.column] = sort.order
    },
    UPDATE_PAGINATION: (state, pagination) => {
        state.pagination[pagination.key] = pagination.value
    },
    EMPTY_MODEL: (state) => {
        state.model = JSON.parse(JSON.stringify(model));
    },
    REFRESH: (state) => {
        state.filter = JSON.parse(JSON.stringify(filter));
        state.sort = JSON.parse(JSON.stringify(sort));
        state.pagination = JSON.parse(JSON.stringify(pagination));
    },
    EMPTY_SERVICES_LIST: (state) => {
        state.selected_list = []
    },
    UPDATE_LANG_COLUMN: (state) => {
        state.columns.id.title = "№";
        state.columns.order_id.title = i18n.t('message.order_id');
        state.columns.patient.title = i18n.t('message.patient');
        state.columns.payment_type_id.title = i18n.t('message.payment_type');
        state.columns.price.title = i18n.t('message.amount');
        state.columns.date.title = i18n.t('message.date_0');
        state.columns.type.title = i18n.t('message.type');
        state.columns.comment.title = i18n.t('message.comment');
        state.columns.created_at.title = i18n.t('message.date');
    }
};
