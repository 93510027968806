import { sort } from "../properties/sort";
import { filter } from "./properties/filter";
import { pagination } from "../properties/pagination";
import { model } from "./properties/model";
import { i18n } from '@/utils/i18n';

export const mutations = {
    SET_LIST: (state, users) => (state.list = users),
    SET_SORT: (state, sort) => (state.sort = sort),
    SET_FILTER: (state, filter) => (state.filter = filter),
    SET_SHOW_PAGINATION: (state, pagination) => (state.show_pagination = pagination),
    SET_PAGINATION: (state, pagination) => (state.pagination = pagination),
    SET_BONUS: (state, data) => (state.bonus = data),
    SET_HOSPITAL_BONUS: (state, data) => (state.hospital_bonus = data),
    SET_SECONDARY_BONUS: (state, data) => (state.secondary_bonus = data),
    UPDATE_PAGINATION: (state, pagination) => {
        state.pagination[pagination.key] = pagination.value
    },
    SET_REPORTS: (state, user) => {
        state.reports = JSON.parse(JSON.stringify(user));
    },
    UPDATE_COLUMN: (state, obj) => {
        state.columns[obj.key].show = obj.value
    },
    UPDATE_SORT: (state, sort) => {
        state.sort[sort.column] = sort.order
    },
    SET_MODEL: (state, user) => {
        state.model = JSON.parse(JSON.stringify(user));
    },
    EMPTY_MODEL: (state) => {
        state.model = JSON.parse(JSON.stringify(model));
        state.permissions = [];
    },
    REFRESH: (state) => {
        state.filter = JSON.parse(JSON.stringify(filter));
        state.sort = JSON.parse(JSON.stringify(sort));
        state.pagination = JSON.parse(JSON.stringify(pagination));
    },
    UPDATE_SHOW_PAGINATION: (state, pagination) => {
        state.show_pagination[pagination.key] = pagination.value
    },
    UPDATE_LANG_COLUMN: (state) => {
        state.columns.id.title = '№';
        state.columns.name.title = i18n.t('message.name');
        state.columns.bonus_amount.title = i18n.t('message.bonus_amount');
        state.columns.surname.title = i18n.t('message.surname');
        state.columns.patronymic.title = i18n.t('message.patronymic');
        state.columns.phone.title = i18n.t('message.phone');
        state.columns.password.title = i18n.t('message.password');
        state.columns.gender.title = i18n.t('message.gender');
        // state.columns.id_code.title = i18n.t('message.id_code');
        // state.columns.last_login.title = i18n.t('message.last_login');
        // state.columns.room_id.title = i18n.t('message.rooms_number');
        state.columns.settings.title = i18n.t('message.settings');
    }

};
